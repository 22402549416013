import HeaderOptions from "./HeaderOptions";
import Response from "./Response";

type ErrorCallback = ((excption: any) => void) | undefined;

type ErrorService = ((exception: any, errorCallback: ErrorCallback) => Response) | undefined;

type SuccessfullCallback = ((data: object | string | undefined) => void) | undefined;

export default class RestOptions {
  #headerOptions: HeaderOptions | undefined;
  #errorService: ErrorService;
  #errorCallback: ErrorCallback;
  #successfullCallback: SuccessfullCallback;

  set headerOptions(headerOptions: HeaderOptions | undefined) {
    this.#headerOptions = headerOptions;
  }

  get headerOptions(): HeaderOptions | undefined {
    return this.#headerOptions;
  }

  set errorService(errorService: ErrorService) {
    this.#errorService = errorService;
  }

  get errorService(): ErrorService {
    return this.#errorService;
  }

  set errorCallback(errorCallback: ErrorCallback) {
    this.#errorCallback = errorCallback;
  }

  get errorCallback(): ErrorCallback {
    return this.#errorCallback;
  }

  set successfullCallback(successfullCallback: SuccessfullCallback) {
    this.#successfullCallback = successfullCallback;
  }

  get successfullCallback(): SuccessfullCallback {
    return this.#successfullCallback;
  }
}
