import axios, { AxiosResponse } from "axios";
import Response from "./Response";
import Request, { Method } from "./Request";
import RestOptions from "./RestOptions";
import HeaderOptions from "./HeaderOptions";

async function sendRequest(request: Request, requestData: Object, options: RestOptions): Promise<Response> {
  try {
    const axiosResponse = await sendAxiosRequest(request, requestData, options?.headerOptions);
    const response = new Response(axiosResponse.status, axiosResponse.statusText, axiosResponse.data);

    //Execute any callback
    if (Array.isArray(options?.successfullCallback)) {
      options.successfullCallback.forEach(method => method(response.data));
    } else if (options?.successfullCallback) {
      options.successfullCallback(response.data);
    }

    return response;
  } catch (e) {
    return options?.errorService
      ? options.errorService(e, options.errorCallback)
      : new Response(e.response.statusCode, e.message, undefined);
  }
}

async function sendAxiosRequest(
  request: Request,
  data: Object,
  headerOptions: HeaderOptions | undefined,
): Promise<AxiosResponse<any>> {
  switch (request.method) {
    case Method.GET:
      return await axios.get(request.url, headerOptions?.config);
    case Method.POST:
      return await axios.post(request.url, data, headerOptions?.config);
    case Method.PUT:
      return await axios.put(request.url, data, headerOptions?.config);
    case Method.DELETE:
      return await axios.delete(request.url, headerOptions?.config);
    default:
  }

  throw new Error("Method is not set");
}

export default sendRequest;
