class Response {
  #statusCode: number;
  #message: string;
  #data: object | string | undefined;

  constructor(httpStatusCode: number, message: string, data: object | string | undefined) {
    this.#statusCode = httpStatusCode;
    this.#message = message;
    this.#data = data;
  }

  get statusCode(): number {
    return this.#statusCode;
  }

  get message(): string {
    return this.#message;
  }

  get data(): object | string | undefined {
    return this.#data;
  }

  isOk(): boolean {
    return [200, 201, 204].includes(this.statusCode);
  }

  isAccepted(): boolean {
    return this.statusCode === 202;
  }
}

export default Response;
