import ReducerType from "../ReducerType";
import { ACTION } from "./defaultSessionVariables";

type Action = {
  type: ACTION;
  sessionId: string | undefined;
};

const initialState = {
  initialized: false,
};

const sessionReducer: ReducerType = (state: object | undefined = initialState, action: Action) => {
  switch (action.type) {
    case ACTION.SESSION_ID:
      return Object.assign({}, state, {
        sessionId: action.sessionId,
      });
    case ACTION.INITIALIZED: {
      return Object.assign({}, state, {
        initialized: true,
      });
    }
    default:
      return state;
  }
};

export default sessionReducer;
