import { SESSION_ID, REMEMBER_ME } from "./storeVariables";

enum STORAGE {
  LOCAL = "LOCAL_STORAGE",
  SESSION = "SESSION_STORAGE",
}

type Data = object | string | number | boolean | null;

type RememberMe = boolean | null | undefined;

export async function loadItem(item: string): Promise<Data> {
  //Use local storage only if rememberMe is true
  const data = await _getStorage(await _getRememberMe(null)).getItem(item);

  //If no success - return null
  if (data === null) {
    return data;
  }

  try {
    //Try to parse as json object
    return JSON.parse(data);
  } catch (e) {
    //No success, try as boolean
    if (data === "true") return true;
    else if (data === "false") return false;

    return isNumber(data) ? Number(data) : data === "null" ? null : data;
  }
}

export async function saveItem(item: string, data: Data, rememberMe: RememberMe): Promise<void> {
  const saveData: string | null = typeof data === "string" ? data : data === null ? "null" : JSON.stringify(data);

  //If it is the rememberMe setting itself, use it!
  if (item === REMEMBER_ME) {
    const booleanData = !!data;
    const previousRememberMe = await _getRememberMe(booleanData);
    if (rememberMe === previousRememberMe) return;
    rememberMe = booleanData;

    if (!rememberMe) {
      _moveTo(STORAGE.SESSION);
      await localStorage.clear();
    } else {
      _moveTo(STORAGE.LOCAL);
    }
  }

  return await _getStorage(!!rememberMe).setItem(item, saveData);
}

export async function deleteItem(item: string): Promise<void> {
  await localStorage.removeItem(item);
  await sessionStorage.removeItem(item);
}

function _getStorage(rememberMe: boolean) {
  return !rememberMe ? sessionStorage : localStorage;
}

const _moveTo = async (toStorage: STORAGE): Promise<void> => {
  const fromData: string | null =
    toStorage === STORAGE.LOCAL ? await sessionStorage.getItem(SESSION_ID) : await localStorage.getItem(SESSION_ID);
  const saveData = fromData === null ? "" : fromData;

  if (toStorage === STORAGE.LOCAL) await localStorage.setItem(SESSION_ID, saveData);
  else await sessionStorage.setItem(SESSION_ID, saveData);
};

const _getRememberMe = async (rememberMe: RememberMe): Promise<boolean> => {
  if (rememberMe === undefined || rememberMe === null) {
    //Try to get the remember me setting, first from local Storage, then session Storage
    let rememberMeFromStorage = await localStorage.getItem(REMEMBER_ME);
    if (rememberMeFromStorage === null) rememberMeFromStorage = await sessionStorage.getItem(REMEMBER_ME);

    //Fallback
    if (rememberMeFromStorage === null) rememberMe = false;
    else rememberMe = rememberMeFromStorage === "true";
  }

  return rememberMe;
};

function isNumber(data: string) {
  return Number.isNaN(Number(data));
}
