import React from "react";

type FeedbackState = {
  isLoading: boolean;
};

const withFeedback = (ChildComponent: React.ComponentType<any>) =>
  class FeedBack extends React.Component<any, FeedbackState> {
    _unmounted: boolean;

    constructor(props: object) {
      super(props);
      this._unmounted = false;

      this.state = {
        isLoading: false,
      };
    }

    componentWillUnmount() {
      this._unmounted = true;
    }

    async handleOnClick(event: MouseEvent) {
      event.preventDefault();
      if (this.state.isLoading) return;
      this.setState({ isLoading: true });
      await this.props.onClick?.(event);
      if (!this._unmounted) {
        this.setState({ isLoading: false });
      }
    }

    render() {
      return (
        <ChildComponent {...this.props} onClick={this.handleOnClick.bind(this)} isLoading={this.state.isLoading} />
      );
    }
  };

export default withFeedback;
