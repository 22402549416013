import React from "react";
import { Response } from "../network";
import { isEqual } from "../utils/comperator";

export type FetchingState = {
  data: object;
  isLoading: boolean;
  error: undefined | null | string;
};

export type LoadMethodType = (props: object, prevProps: object | null) => Promise<Response | undefined>;
export type LoadMethodsType = LoadMethodType | LoadMethodType[];

const withFetching = (loadMethodCalls: LoadMethodsType) => (ChildComponent: React.ComponentType<any>) =>
  class Fetching extends React.Component<any, FetchingState> {
    _mounted: Boolean;

    constructor(props: object) {
      super(props);

      this.state = {
        data: {},
        isLoading: !!loadMethodCalls,
        error: null,
      };

      this._mounted = true;

      if (loadMethodCalls) {
        this.load(loadMethodCalls, null);
      }
    }

    componentDidMount() {
      this.setState({ isLoading: true });
    }

    componentDidUpdate(prevProps: object) {
      if (!isEqual(prevProps, this.props)) {
        if (loadMethodCalls) {
          this.load(loadMethodCalls, prevProps);
        }
      }
    }

    componentWillUnmount() {
      this._mounted = false;
    }

    async load(loadMethods: LoadMethodsType, prevProps: object | null): Promise<void> {
      if (!loadMethods) return;
      if (!this._mounted) return;
      if (isList(loadMethods)) {
        loadMethods.forEach(loadMethod => this.executeLoad(loadMethod, prevProps));
      } else {
        this.executeLoad(loadMethods, prevProps);
      }
    }

    async executeLoad(loadMethod: LoadMethodType, prevProps: object | null): Promise<void> {
      const response = await loadMethod(this.props, prevProps);
      if (!this._mounted) return;
      if (response?.isOk()) {
        const mergedData = Object.assign({}, this.state.data, response.data);
        this.setState({ data: mergedData });
      } else {
        this.setState({
          error: response?.message,
        });
      }
    }

    render() {
      return <ChildComponent {...this.props} {...this.state.data} isLoading={this.state.isLoading} />;
    }
  };

function isList<T>(value: T | T[]): value is T[] {
  return Array.isArray(value);
}

export default withFetching;
